import React from 'react';

export const AnimatedIcon = ({ className }) => {
  return (
    <div className="tw-fixed tw-top-1/2 tw-left-1/2 tw--translate-x-1/2 tw--translate-y-1/2">
      <img
        className={`tw-h-14 tw-w-auto ${className || ''} `}
        src="/assets/img/logo-animated.svg"
        alt=""
      />
    </div>
  );
};
