import { RESERVATION , GET_RESERVATION } from "../constants/actions";

const INIT_STATE = {
  list: [],
  selectedReservation: null,
};

export default (state = INIT_STATE, action) => {
  switch (action?.type) {
    case RESERVATION:
      return {
        ...state,
        list: action.payload,
      };
    case GET_RESERVATION:
      return {
        ...state,
        selectedReservation: action.payload,
      };
    default:
      return state;
  }
};
