import { ADVANTAGES , GET_ADVANTAGES , SET_ADVANTAGE_LANDING_PAGE , SET_ADVANTAGE_PARC_IN_AEROPORT } from "../constants/actions";

const INIT_STATE = {
  advantages: [],
  selectedAdvantage: null,
  advantagelandingpage: false,
  advantageparcinairoport: null,
};

export default (state = INIT_STATE, action) => {
  switch (action?.type) {
    case ADVANTAGES:
      return {
        ...state,
        advantages: action.payload,
      };
    case GET_ADVANTAGES:
      return {
        ...state,
        selectedAdvantage: action.payload,
      };
    case SET_ADVANTAGE_LANDING_PAGE:
      return {
        ...state,
        advantagelandingpage: action.payload,
      };
    case SET_ADVANTAGE_PARC_IN_AEROPORT:
      return {
        ...state,
        advantageparcinairoport: action.payload,
      };

    default:
      return state;
  }
};
