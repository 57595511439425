/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/

export * from './locale';

export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;

export const menuHiddenBreakpoint = 768;

export const apiServer = process.env.REACT_APP_APISERVER;

export const GoogleApiKey = process.env.REACT_APP_GOOGLEAPIKEY;

export const LocationIqKey = process.env.REACT_APP_LOCATIONIQKEY;
