export const defaultLocale = 'fr';

export const localeOptions = [
  { id: 'fr', name: 'Français', direction: 'ltr' },
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const defaultDirection = 'ltr';
