import { TEMPLATE } from '../constants/actions';

const INIT_STATE = [];

export default (state = INIT_STATE, action) => {
  // console.log("action.type", action.type, state);
  switch (action.type) {
    case TEMPLATE:
      return action.payload;
    default:
      return state;
  }
};
