import { combineReducers } from 'redux';

import settings from './settings';
import authreducer from './auth';
import menu from './nav';
import agencyreducer from './agency';
import carsreducer from './cars';
import inboxreducer from './inbox';
import shortcutreducer from './shortcut';
import reservationreducer from './reservation';
import templatereducer from './template';

const reducers = combineReducers({
  settings,
  authreducer,
  menu,
  agencyreducer,
  carsreducer,
  inboxreducer,
  shortcutreducer,
  reservationreducer,
  templatereducer,
});

export default reducers;
